<template>
  <div class="webgl-content">
    <div
      class="progress"
      v-if="progress < 100"
      :style="{
        'background-image': 'url(' + event.options.showroomOptions.loadingBackground + ')',
      }"
    >
      <ProgressSVG :progress="progress" height="200" width="600" :full-logo="true" />
      <div class="progress__text">{{ $t('showroom.loading') }}<span class="blink">...</span></div>
    </div>

    <div v-show="progress === 100" id="unity-container">
      <canvas v-if="isSuperiorVersion" ref="unityCanvas" id="unity-canvas" />
    </div>

    <v-dialog
      :content-class="$vuetify.breakpoint.mobile ? '' : 'v-dialog--overlay'"
      v-model="videoDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="600"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="hideVideo">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <vimeo-video v-if="exhibitorVideo && videoDialog" :videoUrl="exhibitorVideo" />
      </v-card>
    </v-dialog>

    <video-chat
      :user="exhibitor"
      :video-chat="videoChat"
      :video-chat-url="videoChatUrl"
      @hide-video="hideVideoChat"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import WindowUtil from '@/helpers/window/window.helper';
import UnityUtil from '@/helpers/unity/unity.helper';
import DataUtil from '@/helpers/data/data.helper';

import { unityEventListenersMixin } from '@/mixins/unity.eventListeners.mixin';

import {
  APP_EVENT_MODULE,
  UPDATE_UNITY_ID,
} from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import {
  CLEAR_EXHIBITOR,
  GET_EXHIBITOR,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';

import VimeoVideo from '@/components/video/Video.vue';
import ProgressSVG from '@/components/progressSvg/ProgressSVG.vue';
import VideoChat from '@/components/video-chat/VideoChat.vue';

/* eslint-disable */
export default {
  name: 'ShowRoom',
  components: {
    ProgressSVG,
    VimeoVideo,
    VideoChat,
  },
  mixins: [unityEventListenersMixin],
  data: () => ({
    videoClosed: false,
    videoDialog: false,
    videoChat: false,
    videoChatUrl: null,
    exhibitorVideo: null,
    progress: 0,
    config: null,
    loaderUrl: null,
    configUrl: null,
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event', 'unityId']),
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitor']),
    hasShowroom() {
      return this.configUrl && this.loaderUrl;
    },
    isSuperiorVersion() {
      return this.config?.codeUrl;
    },
  },
  methods: {
    ...mapActions(APP_EXHIBITOR_MODULE, [GET_EXHIBITOR, CLEAR_EXHIBITOR]),
    ...mapActions(APP_EVENT_MODULE, [UPDATE_UNITY_ID]),
    unityProgress(unityInstance, progress) {
      UnityUtil.onUnityProgress(unityInstance, progress);
      this.progress = progress * 100;
    },
    loadUnity() {
      UnityUtil.setUnityScript(this.loaderUrl, this.createInstance);
    },
    createInstance() {
      if (this.isSuperiorVersion) {
        this[UPDATE_UNITY_ID]('unity-canvas');
        this.config = UnityUtil.formattedConfig(this.loaderUrl, this.config);

        // eslint-disable-next-line no-undef
        createUnityInstance(this.$refs.unityCanvas, this.config, (progress) => {
          this.progress = progress * 100;
        })
          .then((unityInstance) => UnityUtil.onUnitySuccess(unityInstance))
          .catch((error) => console.error(error));
      } else {
        this[UPDATE_UNITY_ID]('#canvas');

        // eslint-disable-next-line no-undef
        UnityLoader.instantiate('unity-container', this.configUrl, {
          onProgress: this.unityProgress,
        });
      }

      WindowUtil.giveFocusBackToShowroomIfExists(this.unityId);
    },
    hideVideo() {
      this.videoDialog = false;
      this.exhibitorVideo = null;
    },
    showVideo(videoUrl) {
      this.exhibitorVideo = videoUrl;
      this.videoDialog = true;
    },
    showVideoChat(url) {
      this.videoChatUrl = url;
      this.videoChat = true;
    },
    hideVideoChat() {
      this.videoChat = false;
      this.videoChatUrl = null;
      this[CLEAR_EXHIBITOR]();
    },
  },
  async mounted() {
    this.loaderUrl = this.event.options?.showroomOptions?.unityLoaderURL;
    this.configUrl = this.event.options?.showroomOptions?.unityContainerURL;

    if (this.hasShowroom) {
      this.config = await DataUtil.getJsonData(this.configUrl);

      await this.loadUnity();
    }
  },
};
</script>

<style scoped lang="scss">
@import '../styles/core/variables';

#unityVideo > div {
  height: 100%;
  padding: 0 !important;
  background-color: #000;
}

.webgl-content {
  height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &__text {
    font-size: $large-font-size;
    font-weight: $bold;
  }
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#unity-container,
#unity-canvas {
  width: 100%;
  height: 100%;

  &--scroll-disabled {
    overflow: hidden;
  }
}

.hidden {
  display: none;
}

.close {
  position: absolute;
  right: 1rem;
  top: 6rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.video-chat {
  display: flex;
  flex-direction: column;

  &__toolbar {
    flex: 0;
  }

  &__frame {
    flex: 1;
    height: 500px;
  }
}
</style>
